import React from 'react';
import { firestore } from '../firebase';
import { collection, getDocs, writeBatch } from 'firebase/firestore';

const AdminDashboard = () => {
    const wipeCollection = async (collectionName) => {
        const collectionRef = collection(firestore, collectionName);
        const snapshot = await getDocs(collectionRef);
        const batch = writeBatch(firestore);

        snapshot.forEach((doc) => {
            batch.delete(doc.ref);
        });

        await batch.commit();
        alert(`${collectionName} has been wiped.`);
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <h1 className="text-2xl font-bold mb-4">Admin Dashboard</h1>
            <button 
                onClick={() => wipeCollection('messages')} 
                className="bg-red-500 text-white font-bold py-2 px-4 rounded mb-4 hover:bg-red-700"
            >
                Wipe Messages
            </button>
            <button 
                onClick={() => wipeCollection('messages-2')} 
                className="bg-red-500 text-white font-bold py-2 px-4 rounded mb-4 hover:bg-red-700"
            >
                Wipe Messages-2
            </button>
            <button 
                onClick={() => wipeCollection('messages-3')} 
                className="bg-red-500 text-white font-bold py-2 px-4 rounded mb-4 hover:bg-red-700"
            >
                Wipe Messages-3
            </button>
        </div>
    );
};

export default AdminDashboard;
