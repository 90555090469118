import React, { useState } from 'react';

const PasswordGate = ({ onPasswordCorrect }) => {
    const [password, setPassword] = useState('');
    const [selectedChat, setSelectedChat] = useState('');
    const [error, setError] = useState('');

    const passwords = {
        'messages': '',
        'messages-2': 'password2',
        'messages-3': 'password3'
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (selectedChat) {
            if (selectedChat === 'messages' || password === passwords[selectedChat]) {
                onPasswordCorrect(selectedChat);
            } else {
                setError('Incorrect password. Please try again.');
            }
        }
    };

    return (
        <div className="flex items-start justify-center h-screen bg-gray-100 pt-20">
            <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg w-full max-w-xs">
                <h1 className="text-xl font-bold mb-4">Select Chatroom</h1>
                <select
                    value={selectedChat}
                    onChange={(e) => {
                        setSelectedChat(e.target.value);
                        setError('');
                    }}
                    className="w-full p-2 border rounded-lg mb-4"
                >
                    <option value="">Select Chat</option>
                    <option value="messages">Public</option>
                    <option value="messages-2">Private</option>
                    <option value="messages-3">Executive</option>
                </select>
                {selectedChat !== 'messages' && (
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        className="w-full p-2 border rounded-lg mb-4"
                        disabled={!selectedChat}
                    />
                )}
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" disabled={!selectedChat}>
                    Enter
                </button>
            </form>
        </div>
    );
};

export default PasswordGate;
