import React, { useState } from 'react';

const AdminPasswordGate = ({ onPasswordCorrect }) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        const correctPassword = '2117'; // Replace with your actual admin password

        if (password === correctPassword) {
            onPasswordCorrect();
        } else {
            setError('Incorrect password, please try again.');
        }
    };

    return (
        <div className="flex items-start justify-center h-screen bg-gray-100 pt-20">
            <div className="w-full max-w-xs bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <h2 className="text-2xl mb-4">Admin Login</h2>
                <form onSubmit={handlePasswordSubmit} className="flex flex-col items-center">
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Admin Password"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Submit
                    </button>
                    {error && <p className="text-red-500 mt-2">{error}</p>}
                </form>
            </div>
        </div>
    );
};

export default AdminPasswordGate;
