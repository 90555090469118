import React, { useState, useEffect, useRef, useCallback } from 'react';
import { auth, firestore, storage } from '../firebase';
import { collection, query, orderBy, limit, addDoc, serverTimestamp, startAfter, getDocs, onSnapshot } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const Chat = ({ chatName, onBack }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [file, setFile] = useState(null);
    const fileInputRef = useRef();
    const messagesContainerRef = useRef();
    const [user] = useAuthState(auth);
    const [lastDoc, setLastDoc] = useState(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const loadMessages = useCallback(() => {
        const q = query(
            collection(firestore, chatName),
            orderBy('createdAt', 'desc'),
            limit(20)
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const newMessages = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setMessages(newMessages.reverse());
            setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
            setHasMore(snapshot.docs.length === 20);
        });

        return unsubscribe;
    }, [chatName]);

    const loadMoreMessages = useCallback(async () => {
        if (!lastDoc || loadingMore) return;
        setLoadingMore(true);

        const q = query(
            collection(firestore, chatName),
            orderBy('createdAt', 'desc'),
            startAfter(lastDoc),
            limit(20)
        );

        const snapshot = await getDocs(q);
        const newMessages = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        setMessages(prev => {
            const combinedMessages = [...newMessages.reverse(), ...prev];
            const uniqueMessages = combinedMessages.filter(
                (v, i, a) => a.findIndex(t => (t.id === v.id)) === i
            );
            return uniqueMessages.sort((a, b) => a.createdAt - b.createdAt);
        });

        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
        setHasMore(snapshot.docs.length === 20);
        setLoadingMore(false);
    }, [lastDoc, loadingMore, chatName]);

    useEffect(() => {
        const unsubscribe = loadMessages();
        return () => unsubscribe();
    }, [chatName, loadMessages]);

    const sendMessage = async (e) => {
        e.preventDefault();

        let attachmentURL = null;
        let isImage = false;
        if (file) {
            const fileRef = ref(storage, `${chatName}/${file.name}`);
            await uploadBytes(fileRef, file);
            attachmentURL = await getDownloadURL(fileRef);
            isImage = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(file.type);
        }

        const newMsg = {
            text: newMessage,
            createdAt: serverTimestamp(), // Firestore will handle the timestamp
            uid: user.uid,
            email: user.email,
            photoURL: user.photoURL || '/default-profile.png',
            attachmentURL: attachmentURL,
            fileName: file ? file.name : null,
            isImage: isImage,
        };

        await addDoc(collection(firestore, chatName), newMsg);

        setNewMessage('');
        setFile(null);
        fileInputRef.current.value = '';
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 overflow-auto p-4" ref={messagesContainerRef} style={{ paddingTop: '4rem' }}>
                {loadingMore && <div className="text-center">Loading...</div>}
                {!loadingMore && hasMore && (
                    <button onClick={loadMoreMessages} className="w-full text-center py-2 bg-gray-200 hover:bg-gray-300 rounded mb-4">
                        Load More...
                    </button>
                )}
                {messages.map((msg) => (
                    <div key={msg.id} className={`flex items-start mb-4 ${msg.uid === user.uid ? 'justify-end' : ''}`}>
                        <img src={msg.photoURL || '/default-profile.png'} alt="Avatar" className="w-10 h-10 rounded-full mr-4" />
                        <div className={`p-2 rounded-lg ${msg.uid === user.uid ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
                            {msg.uid !== user.uid && <small className="text-gray-500">{msg.email}</small>}
                            <p>{msg.text}</p>
                            {msg.attachmentURL && (
                                msg.isImage ? (
                                    <div className="mt-2">
                                        <a href={msg.attachmentURL} target="_blank" rel="noopener noreferrer">
                                            <img src={msg.attachmentURL} alt="Attachment" className="max-w-xs rounded-lg" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                                        </a>
                                    </div>
                                ) : (
                                    <div className="flex items-center mt-2">
                                        <a href={msg.attachmentURL} target="_blank" rel="noopener noreferrer" className="flex items-center">
                                            <img src="/clip.png" alt="Attachment" className='opacity-50' style={{ width: '24px', height: '24px' }} />
                                            <span className="ml-2 text-white">{msg.fileName}</span>
                                        </a>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                ))}
                <div ref={messagesContainerRef}></div>
            </div>
            <form onSubmit={sendMessage} className="flex flex-col p-4 bg-gray-100 sticky bottom-0">
                {file && (
                    <div className="flex items-center mb-2">
                        <img src="/clip.png" alt="Attachment" className="w-6 h-6 mr-2" />
                        <span>{file.name}</span>
                        <button
                            type="button"
                            onClick={() => {
                                setFile(null);
                                fileInputRef.current.value = '';
                            }}
                            className="ml-2 text-red-500"
                        >
                            Remove
                        </button>
                    </div>
                )}
                <div className="flex relative items-center">
                    <input
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Say something..."
                        className="flex-1 p-2 border rounded-lg pr-16"
                    />
                    <input
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        ref={fileInputRef}
                        className="hidden"
                    />
                    <img
                        src="/clip.png"
                        alt="Attach"
                        className="cursor-pointer absolute right-20 top-1/2 transform -translate-y-1/2 opacity-50"
                        onClick={() => fileInputRef.current.click()}
                        style={{ width: '20px', height: '20px' }}
                    />
                    <button type="submit" className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Send
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Chat;
