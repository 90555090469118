import React, { useState } from 'react';
import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import Chat from './components/Chat';
import Login from './components/Login';
import PasswordGate from './components/PasswordGate';
import AdminDashboard from './components/AdminDashboard';
import AdminPasswordGate from './components/AdminPasswordGate'; // Import the new component

function App() {
    const [user] = useAuthState(auth);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isAdminAuthorized, setIsAdminAuthorized] = useState(false); // New state for admin authorization
    const [chatName, setChatName] = useState('');

    const handlePasswordCorrect = (chatName) => {
        setChatName(chatName);
        setIsAuthorized(true);
    };

    const handleAdminPasswordCorrect = () => {
        setIsAdminAuthorized(true);
    };

    const resetAuthorization = () => {
        setIsAuthorized(false);
        setChatName('');
    };

    return (
        <Router>
            <div className="App">
                <header className="bg-white text-black p-4 flex justify-between items-center shadow-md sticky top-0 z-10">
                    <div className="flex items-center">
                        {isAuthorized && (
                            <button onClick={resetAuthorization} className="mr-2 text-blue-500 hover:text-blue-700">
                                ← Back
                            </button>
                        )}
                        {isAuthorized && <span className="text-lg mr-4">{chatName} |</span>}
                        {!isAuthorized && (
                            <Link to="/" className="text-lg flex items-center">
                                <img src="ico.png" alt="App Icon" className="h-16 w-16 mr-2" />
                                CyMsg
                            </Link>
                        )}
                    </div>
                    <div className="flex items-center">
                        {user && (
                            <>
                                <Link to="/admin" className="mr-4 text-blue-500 hover:text-blue-700">⚙️</Link>
                                <button
                                    onClick={() => {
                                        setIsAuthorized(false);
                                        setIsAdminAuthorized(false);
                                        auth.signOut();
                                    }}
                                    className="bg-white hover:bg-gray-200 text-black border border-black font-bold py-2 px-4 rounded"
                                >
                                    Sign Out
                                </button>
                            </>
                        )}
                    </div>
                </header>
                <section className="flex-1">
                    <Routes>
                        <Route path="/admin" element={
                            user ? (
                                isAdminAuthorized ? (
                                    <AdminDashboard />
                                ) : (
                                    <AdminPasswordGate onPasswordCorrect={handleAdminPasswordCorrect} />
                                )
                            ) : (
                                <Navigate to="/" />
                            )
                        } />
                        <Route path="/" element={
                            user ? (
                                isAuthorized ? (
                                    <Chat chatName={chatName} onBack={resetAuthorization} />
                                ) : (
                                    <PasswordGate onPasswordCorrect={handlePasswordCorrect} />
                                )
                            ) : (
                                <Login />
                            )
                        } />
                    </Routes>
                </section>
            </div>
        </Router>
    );
}

export default App;
